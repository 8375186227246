import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { Container, Box, Button, Typography, Snackbar, Alert, Modal, IconButton } from '@mui/material';
import { getUserInfo } from '../features/user/userState';
// @mui
import ConfigurationDataCard from '../components/data-card/ConfigurationDataCard';
// redux
// import { getUserInfo } from '../features/user/userState';
import { getConfig, getConfigData, putConfigData, getConfigUpdated } from '../features/config/configState';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function ConfigPage() {
  const [activeSection, setActiveSection] = useState('appRunner');
  const [showConfigUpdated, setShowConfigUpdated] = useState(false); // to show the alert after data send to backend
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saveEnable, setsaveEnable] = useState(false);

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const configData = useAppSelector(getConfigData);
  const isConfigUpdated = useAppSelector(getConfigUpdated);
  const updateSaveEnableState = () => {
    setsaveEnable(true);
  };

  useEffect(() => {
    setShowConfigUpdated(isConfigUpdated);
  }, [isConfigUpdated]);

  const defaultValue = {
    REFRESH_STEP_FUNC_ARN: configData?.aws?.refresh_step_func_arn || '',
    BUCKET_NAME: configData?.aws?.bucket_name || '',
    BUCKET_FOLDER_NAME: configData?.aws?.bucket_folder_name || '',
    ITERABLE_API_KEY: configData?.iterable?.api_key || '',
    ITERABLE_URL: configData?.iterable?.url || '',
    ITERABLE_WORKFLOW_ID: configData?.iterable?.workflowId || '',
    SERVICE_UPS_USERNAME: configData?.ups?.ups_username || '',
    ACCESS_TOKEN: configData?.magento?.access_token || '',
    BASE_URL: configData?.website?.base_url || '',
    MAGENTO_BASE_URL: configData?.magento?.magento_base_url || '',
    EMAIL_FROM: configData?.report?.email_from || '',
    EMAIL_TO: configData?.report?.email_to || '',
    MANUAL_REPLACEMENT_EMAIL_TO: configData?.replacement?.email_to || '',
    IS_PROD: configData?.website?.is_prod || '',
    ITERABLE_API_KEY: configData?.iterable?.api_key || '',
    SERVICE_UPS_PASSWORD: configData?.ups?.ups_password || '',
    SERVICE_USPS_USER_ID: configData?.usps?.usps_user_id || '',
    REPLACEMENT_ORDER_SHIPPING: configData?.replacement?.replace_order_ship,
    UPS_WAIT_HOURS: configData?.replacement?.ups_waiting_hours || '',
    USPS_WAIT_HOURS: configData?.replacement?.usps_waiting_hours || '',
    REPLACEMENT_DAILY_LIMIT: configData?.replacement?.daily_limit,
    MIDDLEWARE_URL: configData?.middleware?.url || '',
    MAGENTO_API_KEY: configData?.middleware?.magento_apikey || '',
    APPLICATION_NAME: configData?.middleware?.application_name || '',
    SERVICE_UPS_CLIENT_ID: configData?.ups?.ups_client_id || '',
    SERVICE_UPS_CLIENT_SECRET: configData?.ups?.ups_client_secret || '',
    SERVICE_USPS_CLIENT_ID: configData?.usps?.usps_client_id || '',
    SERVICE_USPS_CLIENT_SECRET: configData?.usps?.usps_client_secret || '',
    SERVICE_USPS_TOKEN_REFRESH_BUFFER_TIME: configData?.usps?.usps_token_refresh_buffer_time_in_seconds || '',
    RETURN_TICKET_CREATE: configData?.is_allow_return_ticket_create || '',
    RETURN_TARGET_DATE: configData?.return_target_date || '',
    REMOVE_PRODUCT_FROM_ITERABLE_MAIL: configData?.is_remove_product_from_iterable_mail || '',
    MAGENTO_CUSTOMER_URL: configData?.magento?.customer_account_url || '',
    SHIPSTATION_USERNAME: configData?.shipstation?.shipstation_username || '',
    SHIPSTATION_PASSWORD: configData?.shipstation?.ship_station_password || '',
    KASA_BASE_URL: configData?.kasa?.kasa_base_url || '',
    KASA_API_KEY: configData?.kasa?.kasa_api_key || ''
  };

  useEffect(() => {
    dispatch(getConfig(userInfo?.accessToken));
  }, []);

  const configurationSections = [
    {
      title: 'AWS',
      fields: [
        {
          label: 'REFRESH_STEP_FUNC_ARN',
        },
        {
          label: 'BUCKET_NAME',
        },
        {
          label: 'BUCKET_FOLDER_NAME',
        },
      ],
    },
    {
      title: 'Iterable',
      fields: [
        {
          label: 'ITERABLE_API_KEY',
        },
        {
          label: 'ITERABLE_URL',
        },
        {
          label: 'ITERABLE_WORKFLOW_ID',
        },
        {
          label: 'REMOVE_PRODUCT_FROM_ITERABLE_MAIL',
          type: 'toggle',
        },
      ],
    },
    {
      title: 'UPS',
      fields: [
        {
          label: 'SERVICE_UPS_USERNAME',
        },
        {
          label: 'SERVICE_UPS_PASSWORD',
        },
        {
          label: 'SERVICE_UPS_CLIENT_ID',
        },
        {
          label: 'SERVICE_UPS_CLIENT_SECRET',
        },
      ],
    },
    {
      title: 'USPS',
      fields: [
        {
          label: 'SERVICE_USPS_USER_ID',
        },
        {
          label: 'SERVICE_USPS_CLIENT_ID',
        },
        {
          label: 'SERVICE_USPS_CLIENT_SECRET',
        },
        {
          label: 'SERVICE_USPS_TOKEN_REFRESH_BUFFER_TIME',
        },
      ],
    },
    {
      title: 'Report',
      fields: [
        {
          label: 'EMAIL_FROM',
        },
        {
          label: 'EMAIL_TO',
        },
      ],
    },
    {
      title: 'Middleware',
      fields: [
        {
          label: 'MIDDLEWARE_URL',
        },
        {
          label: 'MAGENTO_API_KEY',
        },
        {
          label: 'APPLICATION_NAME',
        },
      ],
    },
    {
      title: 'Website',
      fields: [
        {
          label: 'BASE_URL',
        },
        {
          label: 'IS_PROD',
        },
      ],
    },
    {
      title: 'Magento',
      fields: [
        {
          label: 'MAGENTO_BASE_URL',
        },
        {
          label: 'ACCESS_TOKEN',
        },
        {
          label: 'MAGENTO_CUSTOMER_URL',
        },
      ],
    },
    {
      title: 'Replacement Order',
      fields: [
        {
          label: 'UPS_WAIT_HOURS',
        },
        {
          label: 'USPS_WAIT_HOURS',
        },
        {
          label: 'REPLACEMENT_ORDER_SHIPPING',
          type: 'toggle',
          notRequire: true,
        },
        {
          label: 'MANUAL_REPLACEMENT_EMAIL_TO',
        },
        {
          label: 'REPLACEMENT_DAILY_LIMIT',
          validate: (value) => /^[1-9]\d*$/.test(value) || 'Must be a positive whole number',
        },
      ],
    },
    {
      title: 'Return',
      fields: [
        {
          label: 'RETURN_TARGET_DATE',
        },
        {
          label: 'RETURN_TICKET_CREATE',
          type: 'toggle',
          notRequire: true,
        },
      ],
    },
    {
      title: 'Shipstation',
      fields: [
        {
          label: 'SHIPSTATION_USERNAME',
        },
        {
          label: 'SHIPSTATION_PASSWORD',
        },
      ],
    },
    {
      title: 'KASA',
      fields: [
        {
          label: 'KASA_BASE_URL',
        },
        {
          label: 'KASA_API_KEY',
        },
      ],
    },
  ];

  const StyledRoot = styled('div')(() => ({
    maxWidth: '100%',
  }));
  const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    color: theme.palette.common.black,
    // padding: theme.spacing(12, 0),
  }));
  const getHeadingStyle = (section) => ({
    fontSize: { lg: 16, md: 12, sm: 10 },
    color: activeSection === section ? '#004152' : '#878484',
    borderBottom: activeSection === section ? '2px solid #004152' : 'none',
    cursor: 'pointer',
  });
  const btnStyle = {
    width: 188,
    height: 48,
    boxShadow: 'none',
    fontSize: 18,
    fontFamily: 'Whitney Semibold',
  };
  const stylePassModal = {
    position: 'absolute',
    top: { lg: '46%', md: '42.5%', sm: '30%' },
    left: { lg: '47.5%', md: '52%', sm: '50%' },
    transform: 'translate(-50%, -50%)',
    width: 463,
    height: 188,
    borderRadius: '15px',
    background: '#FAFAFA',
    padding: '40px',
    overflow: 'hidden',
    outline: 'none',
  };

  const { handleSubmit, control, formState, reset, register } = useForm();

  useEffect(() => {
    reset(defaultValue);
  }, [reset, configData]);

  const handleAppRunnerSave = async (data) => {
    let token = userInfo?.accessToken;
    let contructData = {
      iterable: {
        url: data.ITERABLE_URL,
        api_key: data.ITERABLE_API_KEY,
        workflowId: data.ITERABLE_WORKFLOW_ID,
      },
      ups: {
        ups_username: data.SERVICE_UPS_USERNAME,
        ups_password: data.SERVICE_UPS_PASSWORD,
        ups_client_id: data.SERVICE_UPS_CLIENT_ID,
        ups_client_secret: data.SERVICE_UPS_CLIENT_SECRET,
        ...(configData?.ups?.ups_client_id !== data.SERVICE_UPS_CLIENT_ID ||
        configData?.ups?.ups_client_secret !== data.SERVICE_UPS_CLIENT_SECRET
          ? { ups_access_token_info: {} }
          : {}),
      },
      usps: {
        usps_user_id: data.SERVICE_USPS_USER_ID,
        usps_client_id: data.SERVICE_USPS_CLIENT_ID,
        usps_client_secret: data.SERVICE_USPS_CLIENT_SECRET,
        usps_token_refresh_buffer_time_in_seconds: data.SERVICE_USPS_TOKEN_REFRESH_BUFFER_TIME,
      },
      report: {
        email_from: data.EMAIL_FROM,
        email_to: data.EMAIL_TO,
      },
      website: {
        base_url: data.BASE_URL,
        is_prod: data.IS_PROD,
      },
      magento: {
        magento_base_url: data.MAGENTO_BASE_URL,
        access_token: data.ACCESS_TOKEN,
        customer_account_url: data.MAGENTO_CUSTOMER_URL,
      },
      aws: {
        refresh_step_func_arn: data.REFRESH_STEP_FUNC_ARN,
        bucket_name: data.BUCKET_NAME,
        bucket_folder_name: data.BUCKET_FOLDER_NAME,
      },
      middleware: {
        url: data.MIDDLEWARE_URL,
        magento_apikey: data.MAGENTO_API_KEY,
        application_name: data.APPLICATION_NAME,
      },
      replacement: {
        ups_waiting_hours: data.UPS_WAIT_HOURS,
        usps_waiting_hours: data.USPS_WAIT_HOURS,
        replace_order_ship: data.REPLACEMENT_ORDER_SHIPPING,
        email_to: data.MANUAL_REPLACEMENT_EMAIL_TO,
        daily_limit: data.REPLACEMENT_DAILY_LIMIT,
      },
      token: token,
      is_allow_return_ticket_create: data.RETURN_TICKET_CREATE,
      return_target_date: data.RETURN_TARGET_DATE,
      is_remove_product_from_iterable_mail: data.REMOVE_PRODUCT_FROM_ITERABLE_MAIL,
      shipstation: {
        shipstation_username: data.SHIPSTATION_USERNAME,
        ship_station_password: data.SHIPSTATION_PASSWORD,
      },
      kasa: {
        kasa_base_url: data.KASA_BASE_URL,
        kasa_api_key: data.KASA_API_KEY,
      }
    };

    const actionResult = await dispatch(putConfigData(contructData));

    if (putConfigData.fulfilled.match(actionResult)) {
      setShowConfigUpdated(true); // Show the success alert
      setShowConfirmation(false);
    } else if (putConfigData.rejected.match(actionResult)) {
      setShowConfigUpdated(false); // Show reject alert
    }
  };

  const handleCancel = () => {
    control._reset(defaultValue);
    setShowConfigUpdated(false);
  };

  const onCloseExportInfo = () => {
    setShowConfigUpdated(false);
  };

  return (
    <StyledRoot>
      <Helmet>
        <title> Configuration | Shipping Insights </title>
      </Helmet>
      <Snackbar
        open={showConfigUpdated}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={onCloseExportInfo}
      >
        <Alert onClose={onCloseExportInfo} severity="success" sx={{ width: '100%' }}>
          Configuration updated.
        </Alert>
      </Snackbar>
      <Typography
        variant="h1"
        sx={{
          color: 'secondary.light',
          fontSize: { lg: 36, sm: 22 },
          fontWeight: 500,
          fontFamily: 'Whitney Semibold',
          mb: 5,
          px: 2,
        }}
      >
        Configurations
      </Typography>
      <Box
        container
        sx={{
          m: { lg: '19px', md: '16px', sm: '16px' },
          pt: 2,
          px: 1,

          color: 'common.black',
          bgcolor: 'common.white',
        }}
      >
        <Box sx={{ ml: 1, display: 'flex', gap: 4, borderBottom: '2px solid secondary.gray' }}>
          <Typography
            variant="subtitle1"
            sx={getHeadingStyle('appRunner')}
            onClick={() => setActiveSection('appRunner')}
          >
            General
          </Typography>
        </Box>
        {/* App runner Node js env update */}
        <Container
          component="form"
          maxWidth="xl"
          sx={{
            padding: '20px',
            bgcolor: 'common.white',
            color: 'common.black',
            display: activeSection === 'appRunner' ? 'block' : 'none',
          }}
        >
          {configurationSections.map((section, index) => (
            <ConfigurationDataCard
              key={index}
              title={section.title}
              fields={section.fields}
              control={control}
              updateSaveEnableState={updateSaveEnableState}
            />
          ))}
          <Box
            sx={{
              height: 150,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              gap: 2,
            }}
          >
            <Button
              size="large"
              type="button"
              variant="contained"
              sx={{
                bgcolor: saveEnable ? 'primary.light' : 'info.button',
                color: saveEnable ? 'common.white' : 'common.black',
                ...btnStyle,
              }}
              onClick={() => {
                if (saveEnable) {
                  setShowConfirmation(true);
                }
              }}
            >
              Save
            </Button>
            <Button
              sx={{
                bgcolor: 'info.button',
                color: 'common.black',
                ...btnStyle,
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Container>
        {/* Confirmation Modal */}
        <Modal open={showConfirmation} onClose={() => setShowConfirmation(false)}>
          <Box sx={stylePassModal}>
            <StyledContent>
              <IconButton
                aria-label="close"
                sx={{
                  position: 'fixed',
                  top: { sm: 15, lg: 16 },
                  right: { lg: 16, md: 16, sm: 10 },
                  zIndex: 2,
                }}
                onClick={() => setShowConfirmation(false)}
              >
                <img width="20px" height="20px" src="/assets/icons/table/close.svg" alt="X" />
              </IconButton>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'secondary.light',
                  fontFamily: 'Whitney',
                  margin: 'auto',
                  fontSize: '16px',
                }}
              >
                Are you sure you want to update the configuration?
              </Typography>
              <Box
                sx={{
                  height: 130,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  gap: 2,
                }}
              >
                <Button
                  sx={{
                    bgcolor: 'info.button',
                    color: 'common.black',
                    ...btnStyle,
                  }}
                  onClick={() => setShowConfirmation(false)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    '&:hover': {
                      color: 'primary.light',
                    },
                    bgcolor: 'primary.light',
                    color: 'common.white',
                    ...btnStyle,
                  }}
                  onClick={handleSubmit(handleAppRunnerSave)}
                >
                  Confirm
                </Button>
              </Box>
            </StyledContent>
          </Box>
        </Modal>
      </Box>
    </StyledRoot>
  );
}
